<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">История рассылок</h4>

            <div class="row">
              <div class="col-lg-12">
                <div class="banners--actions">
                  <div class="create text-right">
                    <div @click="showAddPopup" class="btn btn-sm btn-success">
                      <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table" style="cursor: pointer;">
                  <thead>
                    <tr>
                      <th>{{$t('views.push_notifications.send_to')}}</th>
                      <th>{{$t('views.push_notifications.title')}}</th>
                      <th>{{$t('views.push_notifications.message')}}</th>
                      <th>{{$t('popularWords.status')}}</th>
                      <th>{{$t('views.push_notifications.send')}}</th>
                      <th>{{$t('views.push_notifications.sent')}}</th>
                      <th>{{$t('views.push_notifications.error_descr')}}</th>
                      <th>{{$t('popularWords.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in notifications" :key="item.id">
                      <td>{{ item.send_to }}</td>
                      <td style="word-break: break-all;">{{ item.title }}</td>
                      <td style="word-break: break-all;">{{ item.message }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.create_dt }}</td>
                      <td>{{ item.send_dt }}</td>
                      <td style="word-break: break-all;">{{ item.error_description }}</td>
                      <td>
                        <div @click="showDeletePopup(item.id, item.status)" class="btn btn-sm btn-danger">
                          <i class="fas fa-times"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>    
                </table>
              </div>
            </div>

            <div class="paginator mt-3 mb-3">
              <paginator
                  :pages="pagesCountPaginator"
                  :now="thisPage"
                  @setPage="setPage"
              ></paginator>
            </div>

          </div>
        </div>
      </div>
    </div>

    <popup
        :closeButton="addPopup.closeButton"
        :actionButton="addPopup.actionButton"
        :actionClass="addPopup.actionClass"
        :show="addPopup.show"
        @closePopup="closeAddPopup"
        @actionPopup="addPopupSubmit"
    >
      <div slot="header">{{$t('views.push_notifications.create_push')}}</div>
      <div slot="body">
        <div class="form-group">
          <label for="send_to">{{$t('views.push_notifications.send_to')}}</label>
          <select v-model="newNotification.send_to" class="form-control form-control-sm">
            <option v-for="item in topics" :key="item.id" :value="item.codename">
              {{item.codename}} ({{item.description}})
            </option>
          </select>
          <div v-if="submitted && $v.newNotification.send_to.$error" class="invalid-feedback">
            <span v-if="!$v.newNotification.send_to.required">{{$t('validatePhrases.requiredField')}}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="title">{{$t('views.push_notifications.title')}}</label>
          <textarea
              rows="2"
              id="title"
              v-model="newNotification.title"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': submitted && $v.newNotification.title.$error }"
          ></textarea>
          <div v-if="submitted && $v.newNotification.title.$error" class="invalid-feedback">
            <span v-if="!$v.newNotification.title.required">{{$t('validatePhrases.requiredField')}}</span>
            <span v-if="!$v.newNotification.title.minLength">
              {{$t('validatePhrases.minLength')}}: 1
            </span>
            <span v-if="!$v.newNotification.title.maxLength">
              {{$t('validatePhrases.maxLength')}}: 120
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="message">{{$t('views.push_notifications.message')}}</label>
          <textarea
              rows="4"
              id="message"
              v-model="newNotification.message"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': submitted && $v.newNotification.message.$error }"
          ></textarea>
          <div v-if="submitted && $v.newNotification.message.$error" class="invalid-feedback">
            <span v-if="!$v.newNotification.message.required">{{$t('validatePhrases.requiredField')}}</span>
            <span v-if="!$v.newNotification.message.minLength">
              {{$t('validatePhrases.minLength')}}: 1
            </span>
            <span v-if="!$v.newNotification.message.maxLength">
              {{$t('validatePhrases.maxLength')}}: 350
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="newNotification_create_dt">{{$t('views.push_notifications.send')}}</label>
          <date-picker v-model="newNotification.create_dt" append-to-body :lang="pickerLang" :format="'DD MMM YYYY / HH:mm'"
            :type="'datetime'" confirm class="mr-0 form-control-sm">
          </date-picker>
          <div v-if="submitted && $v.newNotification.create_dt.$error" class="invalid-feedback">
            <span v-if="!$v.newNotification.create_dt.required">{{ $t('validatePhrases.requiredField') }}</span>
          </div>
        </div>
      </div>
    </popup>


    <popup
      :closeButton="deletePopup.closeButton"
      :actionButton="deletePopup.actionButton"
      :actionClass="deletePopup.actionClass"
      :show="deletePopup.show"
      @closePopup="closeDeletePopup"
      @actionPopup="deletePopupSubmit"
    >
      <div slot="header">{{$t('views.push_notifications.delete_push')}}</div>
      <div slot="body">
        {{$t('views.push_notifications.delete_push_text')}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";


import { maxLength, minLength, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from '../../../components/Popup.vue';
//import CreateBanner from '../../../components/management/banners/CreateBanner.vue';

import Formatter from "@/components/libraries/Formatter";
import Paginator from '../../../components/Paginator.vue';
//import EditBanner from '../../../components/management/banners/EditBanner.vue';
const formatter = new Formatter();
//import Paginator from "@/components/Paginator";
//import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
   // Paginator,
    Layout,
    PageHeader,
    DatePicker,
    Paginator
    //CreateBanner,
    //EditBanner
  },
  data() {
    return {
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
      submitted: false,

      topics: [],

      notifications: [],
      newNotification: {
        send_to: '',
        title: '',
        message: '',
        create_dt: ''
      },

      addPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },

      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedNotificationId: 0,

      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },

      title: this.$t('menuitems.management.list.push_notifications'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.push_notifications'),
          active: true
        }
      ],
      //data: [],
    };
  },
  // validations: {
  //   sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  // },
  computed: {

  },
  watch: {
    
  },
  validations: {
    newNotification: {
      send_to: { required },
      title: { required, minLength: minLength(1), maxLength: maxLength(120) },
      message: { required, minLength: minLength(1), maxLength: maxLength(350) },
      create_dt: { required }
    }
  },
  methods: {
    showDeletePopup(notif_id, status){
      if(+status !== 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.push_notifications.stop_delete'),
          time: 6,
          color: "danger"
        });
        return false;
      }
      
      this.deletedNotificationId = notif_id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.deletedNotificationId);

      axios
          .post(`/v1/push/delete`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeletePopup();
            this.loadList();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showAddPopup(){
      this.addPopup.show = true;
    },
    closeAddPopup(){
      this.addPopup.show = false;
    },
    addPopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('send_to', this.newNotification.send_to);
      formData.append('title', this.newNotification.title);
      formData.append('message', this.newNotification.message);
      formData.append('create_dt', formatter.jsObjectToPhpDatetime(this.newNotification.create_dt));

      axios
          .post(`/v1/push/create`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeAddPopup();
            this.newNotification.send_to = '';
            this.newNotification.title = '';
            this.newNotification.message = '';
            this.newNotification.create_dt = '';
            this.loadList();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.loadList();
    },
    loadList(){
      this.$store.dispatch("startPreloader");

      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);

      axios
        .post(`/v1/push/get-list`, formData)
        .then(resp => {
          this.pagesCountPaginator = resp.data.pages;
          this.notifications = resp.data.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getTopics(){
      axios
          .post(`/v1/push/get-topics`)
          .then(resp => {
            this.topics = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getTopics();
    this.loadList();
  }
};
</script>

<style scoped lang="scss">
  
</style>
